<div class="alert alert-danger" role="alert" *ngIf="!players">
  There are no players available
</div>

<div class="main-container" *ngIf="players.length">

  <div class="row align-items-center justify-content-between mb-4">
    <div class="col-12">
      <h1>Players list</h1>
    </div>
  </div>

  <table class="table">
    <thead class="thead-dark">
    <tr>
      <th scope="col">Username</th>
      <th scope="col">Fullname</th>
      <th scope="col">Registration Date</th>
      <th scope="col">Options</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let player of players">
      <th>{{player.userName}}</th>
      <td>{{player.fullName}}</td>
      <td>{{player.registrationDate | date:'long' }}</td>
      <td>
        <a class="btn btn-outline-primary" [routerLink]="'/player/'+player.playerId">
          <i class="far fa-list-alt mr-2"></i><span>Show Transactions</span>
        </a>
      </td>
    </tr>

    </tbody>
  </table>

</div>
