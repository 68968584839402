import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerService } from 'src/app/services/player.service';
import { Player } from 'src/app/interfaces/player';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {

  private idPlayer: number;
  player: Player = {
    playerId: -1,
    userName: '',
    password: '',
    fullName: '',
    registrationDateUTC: '',
    transactions: []
  };

  constructor(private activatedRoute: ActivatedRoute, private playerService: PlayerService) {
    this.activatedRoute.params.subscribe(params => {

      this.idPlayer = params['id'];
      this.playerService.getPlayer(this.idPlayer).subscribe((data: Player) => {
        this.player = data;
        console.log('players', this.player);
      });

    });

  }

  ngOnInit() {

  }

}
