import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../../services/player.service';
import { Player } from '../../interfaces/player';


@Component({
  selector: 'app-player',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {

  players: Player[] = [];

  constructor(private playerService: PlayerService) {
  }

  ngOnInit() {
    this.playerService.getPlayers().subscribe((data: Player[]) => {
      data.forEach(player => player.registrationDate = new Date(player.registrationDateUTC));
      this.players = data;
    });
  }
}
