import { Player } from './../interfaces/player';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  private urlPlayer = 'https://node.test.betserver.es/api/players';

  constructor(private http: HttpClient) {
  }

  getPlayers(): Observable<Player[]> {
    return this.http.get<Player[]>(this.urlPlayer);
  }

  getPlayer(idPlayer: number) {
    const url = `${ this.urlPlayer }/${ idPlayer }`;

    return this.http.get(url).pipe(
      map((data: Player) => data)
    );
  }

}
