<div>
  <div class="row align-items-center justify-content-between mb-4">
    <div class="col-md-9">
      <h1>Transactions of <span>{{player.userName}}</span></h1>
    </div>
    <div class="col-md-3">
      <a class="btn btn-inline" [routerLink]="['/players']">
        <i class="fas fa-chevron-left"></i>
        Return to players
      </a>
    </div>
  </div>

  <div class="alert alert-warning" role="alert" *ngIf="player === undefined || player.transactions?.length === 0">
    No hay transacciones disponibles
  </div>

  <table class="table" *ngIf="player.transactions?.length > 0">
    <thead class="thead-dark">
    <tr>
      <th scope="col">Transaction ID</th>
      <th scope="col">Transaction Type</th>
      <th scope="col">Date</th>
      <th scope="col">Amount</th>
      <th scope="col">Balance after transaction</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let transaction of player.transactions">
      <th>{{transaction.transactionId}}</th>
      <td>{{transaction.transactionType | transactionType}}</td>
      <td>{{transaction.transactionDateUTC | date:'long' }}</td>
      <th class="text-right">{{transaction.amount | number:'2.0-2'}}</th>
      <td></td>
    </tr>

    </tbody>
  </table>

</div>
